import React from 'react';
import {Form, InputGroup} from 'react-bootstrap';

function StringInput(props) {
    return (
        <div className="d-inline-block pr-2" style={{width: '12rem'}}>
            <Form.Group>
                <Form.Label>{props.name}</Form.Label>
                <InputGroup>
                    <Form.Control type="text" placeholder="Zeichenkette" value={props.value} onChange={e => props.onChange(e.target.value)}/>
                </InputGroup>
            </Form.Group>
        </div>
    );
}

export default StringInput;
