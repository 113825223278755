import React, {useState} from 'react';
import ValueRowEditor from "./components/ValueRowEditor";
import {Button, Container} from "react-bootstrap";

function App() {
    const [data, setData] = useState(initialData);
    const [model, setModel] = useState(initialModel);

    return (
        <div>
            <ValueRowEditor model={model} data={data} onEditRow={(rowData) => editRowData(rowData, data, setData)}/>
            <Container fluid={true}>
                <Button variant="success" className="mr-3" onClick={() => addRow(data, setData, model)}>Zeile hinzufügen</Button>
                <Button variant="success" className="mr-3" onClick={() => addColumnInput(model, setModel)}>Input-Spalte hinzufügen</Button>
                <Button variant="success" className="mr-3" onClick={() => addColumnOutput(model, setModel)}>Output-Spalte hinzufügen</Button>
                <Button variant="secondary" className="mr-3" onClick={() => alert(JSON.stringify(model))}>Modell anzeigen</Button>
                <Button variant="secondary" onClick={() => alert(JSON.stringify(data))}>Daten anzeigen</Button>
            </Container>
        </div>
    );
}

function addRow(data, setData, model) {
    const latestValue = data.reduce((prev, current) => (prev.id > current.id) ? prev : current);
    const definedColumns = model.inputValues.concat(model.outputValues);
    const definedColumnNames = definedColumns.map(column => column.name);

    let newRow = {id: latestValue.id + 1};

    // initialize all defined columns for new row to be added.
    for (const columnName of definedColumnNames) {
        newRow = {...newRow, [columnName]: ''};
    }

    let newData = [...data, newRow];
    setData(newData);
}

function addColumnInput(model, setModel) {
    let newModel = {...model};

    newModel.inputValues.push({name: 'Hinzugefügt', type: 'numeric', unit: 'm'});

    setModel(newModel);
}

function addColumnOutput(model, setModel) {
    let newModel = {...model};

    newModel.outputValues.push({name: 'Hinzugefügt', type: 'string'});

    setModel(newModel);
}

function editRowData(rowData, data, setData) {
    let newData = [...data];
    newData = newData.filter(row => row.id !== rowData.id);
    newData.push(rowData);
    setData(newData);
    console.log(rowData);
}

const initialModel = {
    inputValues: [
        {
            name: 'Temperatur',
            type: 'numeric',
            unit: '°C'
        },
        {
            name: 'Körpergewicht',
            type: 'numeric',
            unit: 'kg'
        },
        {
            name: 'Wochentag',
            type: 'string'
        }
    ],
    outputValues: [
        {
            name: 'Getränk',
            type: 'string'
        },
        {
            name: 'Volumen',
            type: 'numeric',
            unit: 'ml'
        }
    ]
};

let initialData = [
    {
        'id': 1,
        'Temperatur': 30,
        'Körpergewicht': 80,
        'Wochentag': 'Freitag',
        'Getränk': 'Apfelschorle',
        'Volumen': 1500
    },
    {
        'id': 2,
        'Temperatur': -10,
        'Körpergewicht': 80,
        'Wochentag': 'Freitag',
        'Getränk': 'Tee',
        'Volumen': 350
    },
    {
        'id': 3,
        'Körpergewicht': 90,
        'Temperatur': 10,
        'Wochentag': 'Samstag',
        'Volumen': 400,
        'Getränk': 'Kaffee'
    }
];

export default App;
