import React from 'react';
import {Form, InputGroup} from 'react-bootstrap';

function NumericInput(props) {
    return (
        <div className="d-inline-block pr-2" style={{width: '12rem'}}>
            <Form.Group>
                <Form.Label>{props.name}</Form.Label>
                <InputGroup>
                    <Form.Control onKeyPress={preventNonNumericInput} type="text" placeholder="Zahl" value={props.value} onChange={e => props.onChange(e.target.value)}/>
                    {props.hasOwnProperty('unit') && props.unit !== null && props.unit !== '' &&
                        <InputGroup.Append>
                            <InputGroup.Text>{props.unit}</InputGroup.Text>
                        </InputGroup.Append>
                    }
                </InputGroup>
            </Form.Group>
        </div>
    );
}

function preventNonNumericInput(e) {
    e.persist();
    console.log(e);
    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '-'].includes(e.key)) {
        e.preventDefault();
    }
}

export default NumericInput;
