import React from 'react';
import {Container} from 'react-bootstrap';
import ValueRow from "./ValueRow";

function ValueRowEditor(props) {
    return (
        <Container fluid={true}>
            {props.data.sort((prev, current) => prev.id - current.id).map((row, index) => <ValueRow key={row.id} model={props.model} data={row} showHeaders={index === 0} onEditRow={props.onEditRow}/>)}
        </Container>
    );
}

export default ValueRowEditor;
