import React from 'react';
import NumericInput from "./NumericInput";
import StringInput from "./StringInput";
import {Button} from "react-bootstrap";

function ValueRow(props) {
    return (
        <div key={props.data.id} style={containerStyle}>
            <div className="d-inline-block">
                {props.showHeaders && <h3>Input</h3>}
                {props.model.inputValues.map(definition => getEditor(definition, props.data, props.onEditRow))}
            </div>
            <div className="d-inline-block">
                {props.showHeaders && <h3>Output</h3>}
                {props.model.outputValues.map(definition => getEditor(definition, props.data, props.onEditRow))}
            </div>
            <Button variant="danger">Löschen</Button>
        </div>
    );
}

function getEditor(definition, rowData, onEditRow) {
    // eslint-disable-next-line default-case
    switch (definition.type) {
        case 'string':
            return <StringInput key={definition.name + rowData.id} name={definition.name} value={rowData[definition.name]} onChange={(value) => onChangeValue(value, definition.name, rowData, onEditRow)}/>;
        case 'numeric':
            return <NumericInput key={definition.name + rowData.id} name={definition.name} unit={definition.unit} value={rowData[definition.name]} onChange={(value) => onChangeValue(value, definition.name, rowData, onEditRow)}/>
    }
}

function onChangeValue(value, columnName, rowData, onEditRow) {
    onEditRow({...rowData, [columnName]: value});
}

const containerStyle = {
    whiteSpace: 'nowrap'
};

export default ValueRow;
